<template>
  <ActionDialog ref="dialog" max_width="50%">
    <template #title>
      <v-card-title class="text-capitalize">
        Add {{ metafields_data.mode === 'variants' ? 'variant' : metafields_data.mode }} Metafield
      </v-card-title>
    </template>

    <template #default="{ options }">
      <v-window style="overflow: visible;">
        <v-btn-toggle :value="metafields_data.mode" @change="$emit('update_mode', $event)" mandatory style="justify-content: space-evenly;" active-class="current-tab">
          <v-row>
            <v-col>
              <v-btn elevation=4 class="button-style rounded-lg" value="product">Product</v-btn>
            </v-col>
            <v-col>
              <v-btn elevation=4 class="button-style rounded-lg" value="variants">Variant</v-btn>
            </v-col>
          </v-row>
        </v-btn-toggle>
      </v-window>

      <v-expand-transition>
        <v-select
          v-if="metafields_data.mode === 'variants'"
          v-model="metafields_data.variant"
          :items="product.variants"
          label="Select Variant"
          :item-text="product.variants[0].variant_name ? 'variant_name' : 'sku'"
          :rules="[v => !!v || 'Variant is required']"
          item-value="variant_id"
          outlined
          hide-details=auto
          :readonly="!access.update_access"
          class="mt-4 rounded-lg"
        ></v-select>
      </v-expand-transition>

      <v-divider class="my-4"/>

      <v-row dense>
        <v-col>
          <v-text-field
            v-model="options.data.namespace"
            label="Namespace"
            outlined
            :rules="[v => !!v || 'Namespace is required', v => /^[\w-]+$/.test(v) || 'Can only contain letters, numbers, underscores (_), and hyphens (-).', v => !!v && v.length <= 255 || 'Namespace must be a maximum of 255 characters.']"
            hide-details=auto
            class="rounded-lg"
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            v-model="options.data.key"
            label="Key"
            outlined
            :rules="[v => !!v || 'Key is required', v => /^[\w-]+$/.test(v) || 'Can only contain letters, numbers, underscores (_), and hyphens (-).', v => (metafields.namespaces.has(options.data.namespace) && !metafields.keys.has(v)) || !metafields.namespaces.has(options.data.namespace) || 'Metafield already exists.', v => !!v && v.length <= 64 || 'Key must be a maximum of 64 characters.']"
            hide-details=auto
            class="rounded-lg"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-select
        v-model="options.data.type"
        label="Type"
        outlined
        :items="metafield_types"
        :rules="[v => !!v || 'Type is required']"
        hide-details=auto
        class="mt-3 rounded-lg"
        @change="onTypeChanged(options.data)"
      ></v-select>
      <MetafieldValue
        v-if="options.data.type !== undefined"
        :metafield="options.data"
        class="mt-3"
      ></MetafieldValue>
    </template>
  </ActionDialog>
</template>

<script>
import ActionDialog from "@/components/ActionDialog.vue";
import MetafieldValue from "@/components/product/website_options/shopify/MetafieldValue.vue";
export default {
  components: {
    ActionDialog,
    MetafieldValue,
  },
  props: {
    product: Object,
    metafields_data: Object,
    website: Object,
    access: Object,
  },
  data() {
    return {
      metafield_types: [
        { text: 'Single line text', value: 'single_line_text_field' },
        { text: 'Multi-line text', value: 'multi_line_text_field' },
        { text: 'Integer', value: 'number_integer' },
        { text: 'Decimal', value: 'number_decimal' },
        { text: 'True or false', value: 'boolean' },
        { text: 'Color', value: 'color' },
        { text: 'Single line text (List)', value: 'list.single_line_text_field' },
        { text: 'Integer (List)', value: 'list.number_integer' },
        { text: 'Decimal (List)', value: 'list.number_decimal' },
        { text: 'Color (List)', value: 'list.color' },
      ],
    }
  },
  methods: {
    onTypeChanged(metafield) {
      metafield.value = undefined;
    },
    showDialog() {
      this.$refs.dialog.showDialog()
        .then(options => {
          this.addMetafield(options);
        }).catch(() => {});
    },
    addMetafield(metafield) {
      // Switch on mode to determine which metafield type to create
      switch (this.metafields_data.mode) {
        case 'product':
          // Add the new metafield to the product metafields array
          this.product.website_options[this.website.website_id].metafields.push(metafield);
          break;
        case 'variants':
          // Find the website variant for the new metafield
          var variant = this.product.website_options[this.website.website_id].variants.find(x => x.variant_id === this.metafields_data.variant);

          // Add the new metafield to the variant's metafields array
          variant.metafields.push(metafield);
          break;
      }
    }
  },
  computed: {
    metafields() {
      // Initialize new Sets for namespaces and keys
      const namespaces = new Set();
      const keys = new Set();

      if (this.metafields_data.mode === 'product') {
        // Add all the product level metafields to the Sets
        for (let metafield of this.product.website_options[this.website.website_id].metafields) {
          namespaces.add(metafield.namespace);
          keys.add(metafield.key);
        }
      } else if (this.metafields_data.mode === 'variants') {
        // Add all the variant level metafields to the Sets
        let variant = this.product.website_options[this.website.website_id].variants.find(x => x.variant_id === this.metafields_data.variant);
        if (variant !== undefined) {
          for (let metafield of variant.metafields) {
            namespaces.add(metafield.namespace);
            keys.add(metafield.key);
          }
        }
      }
      // Returns namespaces and keys for selected product/variant
      return { namespaces, keys };
    }
  }
}
</script>