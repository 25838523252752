<template>
  <div style="position: absolute ; top: 20px; right: 40px;">
    <div @keyup.enter="test"></div>
    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <v-icon v-on="on" :disabled="previous_product === 0" @click="goToProduct(previous_product)">mdi-arrow-left-thin</v-icon>
      </template>
      <span>Previous (J)</span>
    </v-tooltip>
    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <v-icon v-on="on" :disabled="next_product === 0" @click="goToProduct(next_product)">mdi-arrow-right-thin</v-icon>
      </template>
      <span>Next (K)</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  props: {
    next_product: Number,
    previous_product: Number,
    goToProduct: Function,
  },
  methods: {
    handleKeyup(event) {
      // If key pressed is either J or K, user is not inside of an input and there is a valid
      // Next or previous product to go to
      if (event.keyCode === 74 && event.target.tagName === "BODY" && this.previous_product !== 0) {
        this.goToProduct(this.previous_product);
      } else if (event.keyCode === 75 && event.target.tagName === "BODY" && this.next_product !== 0) {
        this.goToProduct(this.next_product);
      }
    }
  },
  created: function() {
    document.addEventListener('keyup', this.handleKeyup);
  },
  destroyed: function() {
    document.removeEventListener('keyup', this.handleKeyup);
  },
}
</script>
