<template>
  <v-card elevation=4 class="rounded-lg" outlined @drop="onDrop" @dragenter="onDragEnter" @dragleave="onDragLeave" :class="{ highlight: dragging }">
    <!-- Add More Images Dialog -->
    <v-dialog v-model="showAddImageDialog" max-width="900">
      <ImageUploadCard
        title="Upload Product Images"
        :show.sync="showAddImageDialog"
        :droppedImages.sync="droppedImages"
        :upload="uploadImages"
      ></ImageUploadCard>
    </v-dialog>
    <!-- Edit Images Dialog -->
    <ProductImageEditDialog
      :show.sync="showEditImageDialog"
      :image="editing_image"
      @update:show="editing_image = {}"
      @delete="removeImage"
      @download="downloadImage"
    ></ProductImageEditDialog>
    <!-- View Images Dialog -->
    <ProductImagesDialog
      :show.sync="showImagesDialog"
      :images="images"
      :selected_image.sync="selected_image"
      @download="downloadImage"
      @change="changeImage"
    ></ProductImagesDialog>

    <v-card-title>Images</v-card-title>
    <v-card-subtitle style="color: grey !important">
      Add additional images by dragging them here.
    </v-card-subtitle>
    <!-- Download Images -->
    <v-tooltip bottom v-if="filtered_images.length != 0">
      <template v-slot:activator="{ on }">
        <v-btn v-on="on" icon large @click="downloadImages" class="mr-16 my-4" style="position: absolute; top: 0; right: 0;"><v-icon>mdi-download</v-icon></v-btn>
      </template>
      <span>Download All Images</span>
    </v-tooltip>
    <!-- Add new Image -->
    <v-tooltip bottom v-if="filtered_images.length != 0">
      <template v-slot:activator="{ on }">
        <v-btn v-on="on" icon large @click="showAddImageDialog = !showAddImageDialog; variantImagesID = -1" :disabled="!access.create_access" class="mr-4 mt-4" style="position: absolute; top: 0; right: 0;"><v-icon>mdi-plus</v-icon></v-btn>
      </template>
      <span>Add More Images</span>
    </v-tooltip>

    <v-divider/>

    <v-card-text>
      <draggable v-if="filtered_images.length != 0" v-model="images" v-bind="dragOptions" :group="{ name: 'images', pull: 'clone', put: false }" @choose="selectImage" @unchoose="deSelectImage" :move="(event) => highlightTarget(event)">
        <transition-group style="justify-content: space-evenly" class="row wrap">
          <div v-for="(image, i) in filtered_images" :key="image.image_name">
            <v-container>
              <v-hover v-slot="{ hover }" open-delay="0">
                <v-card elevation=4 class="image-card rounded-lg" style="height: 200px; width: 200px; overflow: hidden;">
                  <v-img :src="image.image_url" aspect-ratio=1 class="image-border" @click="showImagesDialog = !showImagesDialog; selected_image = i"></v-img>

                  <!-- Trash Can Icon -->
                  <div style="position: absolute; right: 5px; top: 5px;" v-if="access.delete_access && hover">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn v-on="on" small icon @click="removeImage(image.image_id)"><v-icon style="color: white; mix-blend-mode: difference;">mdi-trash-can-outline</v-icon></v-btn>
                      </template>
                      <span>Remove Image</span>
                    </v-tooltip>
                  </div>
                  <!-- Pencil Icon -->
                  <div style="position: absolute; right: 32px; top: 5px;" v-if="access.delete_access && hover">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn v-on="on" small icon @click="showEditImageDialog = !showEditImageDialog; editing_image = image"><v-icon style="color: white; mix-blend-mode: difference;">mdi-pencil-outline</v-icon></v-btn>
                      </template>
                      <span>Edit Image</span>
                    </v-tooltip>
                  </div>
                </v-card>
              </v-hover>
            </v-container>
          </div>
        </transition-group>
      </draggable>
      <div v-else class="hover-cursor" style="border-style: dashed; border-width: 2px; border-color: #cfcfcf; text-align: center;" @click="showAddImageDialog = !showAddImageDialog; variantImagesID = -1">
        <v-icon x-large color="#cfcfcf" style="padding: 15px;">mdi-cloud-upload-outline</v-icon>
      </div>
    </v-card-text>

    <v-divider v-if="images.length > 6"></v-divider>

    <v-card-actions v-if="images.length > 6" class="justify-center" style="padding: 0">
      <v-btn v-if="limit" @click="limit = null" block text>Show More</v-btn>
      <v-btn v-else @click="limit = 6" block text>Show Less</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import JSZip from 'jszip';
import ImageUploadCard from '@/components/ImageUploadCard.vue';
import ProductImageEditDialog from "@/components/product/ProductImageEditDialog.vue"
import ProductImagesDialog from '@/components/product/ProductImagesDialog.vue';
import draggable from "vuedraggable";
export default {
  components: {
    ImageUploadCard,
    ProductImageEditDialog,
    ProductImagesDialog,
    draggable
  },
  props: {
    product_images: Array,
    variants: Array,
    dragging: Boolean,
    image_moving: Boolean,
    access: Object,
    uploadImages: Function,
  },
  data() {
    return {
      showAddImageDialog: false,
      showEditImageDialog: false,
      showImagesDialog: false,
      selected_image: 0,
      variantImagesID: -1,
      firstLoad: true,
      editing_image: {},
      limit: 6,
      droppedImages: [],
      counter: 0,
      dragOptions: {
        animation: 200,
        disabled: !this.access.update_access,
        ghostClass: "ghost",
      }
    }
  },
  computed: {
    images: {
      get: function() {
        return this.product_images;
      }, 
      set: function(images) {
        this.$store.commit("setNewProductImages", images);
      }
    },
    filtered_images() {
      // If limit is defined, then reduce the number of images
      // Always filter the images to only display non-deleted images
      return this.limit ? 
          this.product_images.slice(0, this.limit).filter(x => x.is_deleted === undefined) : 
          this.product_images.filter(x => x.is_deleted === undefined);
    }
  },
  methods: {
    onDragEnter(e) {
      if (["application/x-moz-file", "Files"].includes(e.dataTransfer.types[0])) {
        this.$emit("update:dragging", true);
        this.counter++;
      }
    },
    onDragLeave() {
      if (this.counter > 0) {
        this.counter--;
      }
      if (this.counter === 0) {
        this.$emit("update:dragging", false);
      }
    },
    async onDrop(e) {
      // Only execute the onDrop event if the drag is
      // from a new file (not existing images)
      if (this.dragging) {
        // Open the Product Images Dialog
        this.showAddImageDialog = !this.showAddImageDialog;

        // Initialize an images buffer
        let images = [];

        // Iterate through the dropped files
        for (var file of e.dataTransfer.files) {
          // Initialize an image payload
          var image_payload = {
            file: file,
            urlResized: await this.convertToBase64(file),
            lastModified: file.lastModified,
            size: file.size,
            type: file.type,
            name: file.name
          }
          images.push(image_payload);
        }
        this.droppedImages = images;
      }
      // Reset highlight state
      this.resetDraggingState();
    },
    resetDraggingState() {
      this.$emit("update:dragging", false);
      this.counter = 0;
    },
    async convertToBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });
    },
    selectImage() {
      this.$emit('update:image_moving', true);
    },
    deSelectImage() {
      this.$emit('update:image_moving', false);
      this.$emit('update:targetted_variant', null);
    },
    highlightTarget(event) {
      this.$emit('update:targetted_variant', event.to.id);
    },
    removeImage(image_id) {
      this.$store.commit("removeProductImage", this.product_images.findIndex(x => x.image_id === image_id));
    },
    async downloadImages() {
      if (this.images.length === 1) {
        await this.downloadImage(this.images[0]);
      } else {
        let zip = new JSZip();
        for await (var image of this.images) {
          // Determine the image type from the URL
          let image_type = image.image_url.split('.')[image.image_url.split('.').length-1];
          // Generate an image file name for download
          image.filename = `${image.image_alt_text}_${image.image_order}.${image_type}`;
          const imageData = await fetch(image.image_url);
          const imageBlob = await imageData.blob();
          const imageBase64 = await this.convertToBase64(imageBlob);
          zip.file(image.filename, imageBase64.split('base64,')[1], { base64: true });
        }
        zip.generateAsync({ type: "blob" }).then(content => {
          const link = document.createElement('a');
          link.href = URL.createObjectURL(content);
          link.download = `${this.images[0].image_alt_text} Images.zip`;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        });
      }
    },
    async downloadImage(image) {
      const imageData = await fetch(image.image_url);
      const imageBlob = await imageData.blob()
      const imageURL = URL.createObjectURL(imageBlob)

      const link = document.createElement('a');
      link.href = imageURL;
      link.download = image.image_alt_text;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    changeImage(direction) {
      switch (direction) {
        case 'forward':
          if (this.selected_image === this.images.length-1) {
            this.selected_image = 0;
          } else {
            this.selected_image++;
          }
          break;
        case 'back':
          if (this.selected_image === 0) {
            this.selected_image = this.images.length-1
          } else {
            this.selected_image--;
          }
          break;
      }
    }
  }
}
</script>

<style scoped>
.image-card {
  border-radius: 0.5rem;
  height: 200px;
  width: 200px;
  overflow: hidden;
}
.image-card:hover {
  cursor: grab;
}
.highlight {
  border: 2px solid #1976D2;
}
</style>