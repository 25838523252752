<template>
  <v-card elevation=4 class="rounded-lg" outlined>
    <v-overlay :z-index="0" :value="image_moving && product.variant_type !== null || dragging"></v-overlay>
    <v-card-title class="justify-center" >
      Variants
      <v-spacer></v-spacer>
      <!-- Add new Variant -->
      <!-- Disabled Button -->
      <div v-if="skuLimitMet && product.variant_type !== null">
        <v-tooltip bottom :disabled="!skuLimitMet">
          <template v-slot:activator="{ on }">
            <div v-on="on">
              <v-btn icon large :disabled="true"><v-icon>mdi-plus</v-icon></v-btn>
            </div>
          </template>
          <span>You have reached your sku limit.</span>
        </v-tooltip>
      </div>
      <!-- Enabled Button -->
      <v-tooltip bottom v-else-if="product.variant_type !== null">
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" icon large @click="show_dialog = !show_dialog" :disabled="!access.create_access"><v-icon>mdi-plus</v-icon></v-btn>
        </template>
        <span>Add Product Variant</span>
      </v-tooltip>
      <!-- Convert to Variant -->
      <v-tooltip bottom v-else>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" icon large @click="show_dialog = !show_dialog" :disabled="!access.update_access"><v-icon>mdi-cached</v-icon></v-btn>
        </template>
        <span>Convert to Variant Product</span>
      </v-tooltip>
      <!-- Convert to Single -->
      <v-tooltip bottom v-if="!isSingleVariant && isSingleToConvert">
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" icon large @click="convertToSingle" :disabled="!access.update_access"><v-icon>mdi-trash-can-outline</v-icon></v-btn>
        </template>
        <span>Convert to Single Variant Prodcut</span>
      </v-tooltip>
    </v-card-title>

    <ProductVariantDialog
    :variant="(isSingleVariant ? product.variants[0] : new_variant)"
    :isConvert="isSingleVariant"
    :show.sync="show_dialog"
    @reset="resetNewVariant"
    v-on="$listeners"
    ></ProductVariantDialog>

    <v-divider/>

    <v-card-text style="padding: 12px 0px 0px 0px">
      <v-form v-model="valid" @input="$emit('update:changes_valid', valid)">
        <v-row style="margin: 0; padding: 12px" v-if="!isSingleVariant">
          <v-combobox label="Variant Type" v-model="product.variant_type" :items="['Colour', 'Size', 'Material', 'Style', 'Device']" outlined :rules="variant_type_rules" hide-details :readonly="!access.update_access"></v-combobox>
        </v-row>
      </v-form>

      <v-divider v-if="!isSingleVariant" style="padding: 0px 0px 12px 0px"></v-divider>

      <div v-for="(variant, i) in product.variants" :key="i">
        <draggable @add="onAdd(variant.variant_id)" :id="`variant_${variant.variant_id}`" v-model="drag_dropped_images" v-bind="{disabled: !image_moving}" ghost-class="destination-item" :class="{ blurred: !(targetted_variant === `variant_${variant.variant_id}`) && hover && product.variant_type !== null}" group="images">
          <div @dragenter.stop.prevent="dragEnter" @dragleave.stop.prevent="dragLeave()">
            <ProductVariant
            id="ProductVariant"
            :variantIndex="i"
            :variant="variant"
            :images="product.images"
            :hover.sync="hover"
            :dragging="dragging"
            :targetted_variant="targetted_variant"
            :access="access"
            :sku_rules="sku_rules"
            :barcode_rules="barcode_rules"
            :single_variant="isSingleVariant"
            :single_convert="isSingleToConvert"
            :convertToSingle="convertToSingle"
            :uploadVariantImages="uploadVariantImages"
            v-on="$listeners"
            ></ProductVariant>
          </div>
        </draggable>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import draggable from "vuedraggable";
import ProductVariant from "@/components/product/ProductVariant.vue"
import ProductVariantDialog from "@/components/product/ProductVariantDialog.vue"
export default {
  props: {
    product: Object,
    dragging: Boolean,
    access: Object,
    sku_rules: Array,
    barcode_rules: Array,
    variant_type_rules: Array,
    uploadVariantImages: Function,
    targetted_variant: String,
    image_moving: Boolean,
    additionalSkus: Number
  },
  components: {
    draggable,
    ProductVariant,
    ProductVariantDialog,
  },
  data() {
    return {
      show_dialog: false,
      new_variant: {},
      valid: true,
      hover: false,
      counter: 0,
      drag_dropped_images: []
    }
  },
  watch: {
    product(new_val, old_val) {
      if (new_val.product_id !== old_val.product_id) {
        this.$set(this.new_variant, 'product_id', new_val.product_id);
      }
    }
  },
  created() {
    this.resetNewVariant();
  },
  computed: {
    skuLimitMet() {
      return this.$store.getters.getOrganizationSettings.sku_limit <= this.$store.getters.getOrganizationState.sku_count + this.additionalSkus;
    },
    isSingleVariant() {
      return this.product.variants.filter(variant => variant.is_deleted==undefined).length === 1 && this.product.variant_type === null;
    },
    isSingleToConvert() {
      return this.product.variants.filter(variant => variant.is_deleted==undefined).length === 1;
    }
  },
  methods: {
    onAdd(variant_id) {
      // If the variant is still hovered over dropzone and does not have
      // already have a link and variant type is not null, then link image to variant
      if (this.hover && this.drag_dropped_images[0].variant_id === null && this.product.variant_type !== null && variant_id !== undefined) {
        // Link the image to the variant
        let image_id = this.drag_dropped_images[0].image_id;
        this.$axios.put(`${process.env.VUE_APP_API_BASE_URL}/products/${this.$route.params.id}/variants/${variant_id}/images`, {image_id: image_id})
          .then(() => {
            this.$store.dispatch("showSnackbar", {text: "Image Linked Successfully!", color: "success", timeout: 2500});
            // Set variant_id of image
            this.$store.commit("setVariantImage", { image_id: image_id, variant_id: variant_id });
          })
          .catch(err => {
            console.log(err);
          })
      }

      // Reset to initial state
      this.hover = false;
      this.drag_dropped_images.pop();
      this.counter = 0;
    },
    dragEnter() {
      this.hover = true;
      this.counter++;
    },
    dragLeave() {
      if (this.counter > 0) {
        this.counter--;
      }
      if (this.counter === 0) {
        this.hover = false;
      }
    },
    convertToSingle() {
      this.$store.commit("setProductVariantType", null);
    },
    resetNewVariant() {
      this.new_variant = { product_id: this.product.product_id, prices: [] };
      for (var price of this.product.variants[0].prices) {
        this.new_variant.prices.push({ name: price.name, price: 0, priceLevel_id: price.priceLevel_id });
      }
    }
  }
}
</script>

<style>
.destination-item {
  display: none;
}
.blurred {
  filter: blur(3px);
}
</style>