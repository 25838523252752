export const copyToClipboard = {
  data() {
    return {
      copy_value: '',
      copy_text: 'Copy to clipboard',
      copy_icon: 'mdi-clipboard-multiple-outline',
      copied_text: 'Copy successful!',
      copied_icon: 'mdi-clipboard-check-multiple-outline'
    }
  },
  methods: {
    copyToClipboard(text) {
      this.copy_value = text;
      navigator.clipboard.writeText(text);
      setTimeout(() => {
        this.copy_value = '';
      }, 1000);
    }
  }
}