<template>
  <v-window style="overflow: visible;">
    <v-btn-toggle v-model="current_tab" mandatory style="justify-content: space-evenly;" class="background py-4" active-class="current-tab">
      <v-row>
        <v-col v-for="(tab, i) in tabs" :key="i">
          <v-btn elevation=4 class="button-style rounded-lg" :value="tab.name" @click="initializeTab(tab)">{{ tab.name }}</v-btn>
        </v-col>
      </v-row>
    </v-btn-toggle>
    <v-divider class="mt-1 pb-5" />

    <!-- Product Details Card -->
    <div v-if="current_tab === 'Product Details'">
      <v-row v-if="Object.keys(product).length === 0 || Object.keys(product.variants).length === 0" dense>
        <v-col cols="12" md="6">
          <v-skeleton-loader
            type="card-heading, list-item-three-line, image"
            elevation=2
            style="margin-bottom: 12px;"
          ></v-skeleton-loader>
          <v-skeleton-loader
            type="card-heading, list-item-three-line, list-item-three-line"
            elevation=2
          ></v-skeleton-loader>
        </v-col>
        <v-col cols="12" md="6">
          <v-skeleton-loader
            type="image, actions"
            elevation=2
          ></v-skeleton-loader>
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col cols="12" md="6">
          <!-- Product Main Information -->
          <ProductDetails
          :product="product"
          :brands.sync="brands"
          :access="access"
          :name_rules="name_rules"
          :brand_rules="brand_rules"
          v-on="$listeners"
          ></ProductDetails>

          <!-- Product Variants Information -->
          <ProductVariants
          :image_moving.sync="image_moving"
          :targetted_variant="targetted_variant"
          :product.sync="product"
          :dragging.sync="dragging_new_variant_images"
          :access="access"
          :sku_rules="sku_rules"
          :barcode_rules="barcode_rules"
          :variant_type_rules="variant_type_rules"
          :uploadVariantImages="uploadVariantImages"
          :additionalSkus="additionalSkus"
          v-on="$listeners"
          ></ProductVariants>
        </v-col>
        <v-col cols="12" md="6">
          <v-overlay :z-index="0" :value="dragging_new_images"></v-overlay>
          <!-- Product Images Information -->
          <ProductImages
          :product_images.sync="product.images"
          :variants="product.variants"
          :dragging.sync="dragging_new_images"
          :image_moving="image_moving"
          :access="access"
          :uploadImages="uploadImages"
          v-on="$listeners"
          ></ProductImages>
        </v-col>
      </v-row>
    </div>

    <!-- Website Options Card -->
    <div v-if="current_tab === 'Website Specific Options'">
      <v-row v-if="websites.length === 0" dense>
        <v-col cols=12>
          <v-skeleton-loader
            type="card-heading, divider, list-item-three-line, card-heading, list-item-two-line"
            elevation=2
          ></v-skeleton-loader>
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col cols=12 style="padding-top: 0px">
          <SiteOptionTabs
            :product.sync="product"
            :brands="brands"
            :websites="websites"
            :access="access"
            v-if="websites.length"
            :syncProduct="syncProduct"
            v-on="$listeners"
          ></SiteOptionTabs>
        </v-col>
      </v-row>
    </div>

    <!-- Pricing & Inventory Card -->
    <!-- <div v-if="current_tab === 'Pricing & Inventory'"> -->
    <div v-if="current_tab === 'Pricing'">
      <v-row v-if="Object.keys(product).length === 0 || Object.keys(product.variants).length === 0" dense>
        <v-col cols=12>
          <v-skeleton-loader
            type="card-heading, divider, list-item-three-line, card-heading, list-item-two-line"
            elevation=2
          ></v-skeleton-loader>
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col cols=12>
          <PricingInventoryTabs
            :product="product"
            :access="access"
            v-on="$listeners"
          ></PricingInventoryTabs>
        </v-col>
      </v-row>
    </div>

    <!-- Audit Log Card -->
    <div v-if="current_tab === 'Audit Logs'">
      <AuditLog
        :logs="auditLogs"
      ></AuditLog>
    </div>
  </v-window>
</template>

<script>
import { auditLogsLogic } from '@/mixins/auditLogsMixin.js'
import ProductImages from "@/components/product/ProductImages.vue"
import ProductDetails from "@/components/product/ProductDetails.vue"
import ProductVariants from "@/components/product/ProductVariants.vue"
import SiteOptionTabs from "@/components/product/SiteOptionTabs.vue"
import PricingInventoryTabs from "@/components/product/pricing_inventory/PricingInventoryTabs.vue";
import AuditLog from "@/components/auditLogs/AuditLog.vue"
export default {
  mixins: [auditLogsLogic],
  data() {
    return {
      current_tab: "Product Details",
      tabs: [
        {'name': 'Product Details', 'initialized': false},
        {'name': 'Website Specific Options', 'initialized': false},
        // {'name': 'Pricing & Inventory', 'initialized': false},
        {'name': 'Pricing', 'initialized': false},
        {'name': 'Audit Logs', 'initialized': false},
      ],
      auditLogs: [],
      dragging_new_images: false,
      dragging_new_variant_images: false,
    }
  },
  props: {
    product: Object,
    brands: Array,
    websites: Array,
    access: Object,
    name_rules: Array,
    brand_rules: Array,
    sku_rules: Array,
    barcode_rules: Array,
    variant_type_rules: Array,
    uploadImages: Function,
    uploadVariantImages: Function,
    getWebsiteData: Function,
    syncProduct: Function,
    targetted_variant: String,
    image_moving: Boolean,
    additionalSkus: Number
  },
  components: {
    ProductDetails,
    ProductVariants,
    ProductImages,
    SiteOptionTabs,
    PricingInventoryTabs,
    AuditLog,
  },
  watch: {
    async current_tab(tab) {
      if (tab === 'Audit Logs') {
        this.auditLogs = await this.getAuditLogs(this.$route.params.id, this.$route.path.split('/')[1]);
      }
    }
  },
  methods: {
    initializeTab(tab) {
      if (!tab.initialized) {
        switch (tab.name) {
          case 'Website Specific Options':
            this.getWebsiteData(this.product.product_id);
            break;
        }
        tab.initialized = true;
      }
    }
  }
}
</script>