<template>
<v-dialog v-model="show" max-width="1000" @click:outside="$emit('update:show', false)" @keydown.esc="$emit('update:show', false)">
  <v-card style="max-height: 1000px; overflow: auto;">
    <!-- Add More Images Dialog -->
    <v-dialog v-model="showAddImageDialog" max-width="900" @click:outside="$emit('update:show', true)" @keydown.esc="$emit('update:show', true)">
      <ImageUploadCard
        title="Upload Variant Images"
        :show.sync="showAddImageDialog"
        :droppedImages="droppedImages"
        :upload="(payload) => uploadVariantImages({ ...payload, variant_id: variant_id })"
        @update:show="$emit('update:show', true)"
      ></ImageUploadCard>
    </v-dialog>

    <v-card-title class="justify-center">
      Variant Images
      <v-spacer></v-spacer>

      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" icon @click="showAddImageDialog =! showAddImageDialog; $emit('update:show', false)" :disabled="!access.create_access" ><v-icon>mdi-file-image-plus-outline</v-icon></v-btn>
        </template>
        <span>Add Variant Images</span>
      </v-tooltip>
    </v-card-title>

    <v-divider></v-divider>

    <v-card-text>
      <v-container fluid>
        <v-row dense justify="center" v-if="hasVariantImages">
          <v-col cols="12" md="3" v-for="image in variant_images" :key="image.image_name" style="padding: 8px">
            <v-card elevation="4" class="imageCard" style="border-radius: 10px; height: 200px; width: 200px; overflow: hidden">
              <v-img :src="image.image_url" aspect-ratio="1" class="image-border"></v-img>
              <!-- Trash Can Icon -->
              <div style="position: absolute; right: 0; top: 0">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn v-on="on" icon @click="unlinkImage(image.image_id)"><v-icon>mdi-link-variant-remove</v-icon></v-btn>
                  </template>
                  <span>Unlink Image</span>
                </v-tooltip>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</v-dialog>
</template>

<script>
import ImageUploadCard from "@/components/ImageUploadCard.vue"
export default {
  props: {
    show: Boolean,
    variant_images: Array,
    droppedImages: Array,
    variant_id: Number,
    access: Object,
    uploadVariantImages: Function,
  },
  data() {
    return {
      showAddImageDialog: false,
    };
  },
  components: {
    ImageUploadCard,
  },
  computed: {
    hasVariantImages() {
      return this.variant_images[0].image_url !== process.env.VUE_APP_NO_IMAGE_IMG;
    }
  },
  methods: {
    unlinkImage(image_id) {
      this.$axios.delete(`${process.env.VUE_APP_API_BASE_URL}/products/${this.$route.params.id}/variants/${this.variant_id}/image/${image_id}`)
        .then(() => {
          this.$store.dispatch("showSnackbar", {text: "Image Unlinked Successfully!", color: "success", timeout: 2500});
          // Set variant_id of image
          this.$store.commit("setVariantImage", { image_id: image_id, variant_id: null });
        })
        .catch(err => {
          console.log(err);
        })
    },
  },
  watch: {
    droppedImages(images) {
      // If images added
      if (images.length > 0) {
        this.showAddImageDialog = !this.showAddImageDialog;
        this.$emit('update:show', false);
      }
    }
  }
};
</script>
