<template>
  <v-select
    v-if="metafield.type === 'boolean'"
    v-model="metafield.value"
    :items="[{ text: 'True' , value: true }, { text: 'False', value: false }]"
    label="Value"
    outlined
    :rules="rules[metafield.type]"
    hide-details=auto
    class="rounded-lg"
  ></v-select>

  <v-textarea
    v-else-if="metafield.type === 'multi_line_text_field'"
    v-model="metafield.value"
    label="Value"
    outlined
    auto-grow
    rows="1"
    :rules="rules[metafield.type]"
    hide-details=auto
    class="rounded-lg"
  ></v-textarea>

  <v-combobox
    v-else-if="metafield.type.includes('list.')"
    v-model="metafield.value"
    label="Value"
    outlined
    small-chips
    deletable-chips
    multiple
    placeholder="Tip: to add values, type a new value then press Enter or Tab"
    :rules="rules[metafield.type]"
    hide-details=auto
    class="rounded-lg"
  ></v-combobox>

  <v-text-field
    v-else-if="metafield.type === 'number_decimal' || metafield.type === 'number_integer'"
    v-model="metafield.value"
    label="Value"
    outlined
    type="number"
    :rules="rules[metafield.type]"
    hide-details=auto
    class="rounded-lg"
  ></v-text-field>

  <v-text-field
    v-else
    v-model="metafield.value"
    label="Value"
    outlined
    :rules="rules[metafield.type]"
    hide-details=auto
    class="rounded-lg"
  ></v-text-field>
  
</template>

<script>
export default {
  props: {
    metafield: Object,
  },
  data() {
    return {
      rules: {
        'single_line_text_field': [v => !!v || v === '' || 'Must not be empty.'],
        'multi_line_text_field': [v => !!v || v === '' || 'Must not be empty.'],
        'boolean': [v => v !== undefined || 'Must be true or false.'],
        'color': [v => /^[#][0-9A-Fa-f]{6}$/.test(v) || 'Must be a hexadecimal number.'],
        'number_decimal': [v => /^[-+]?[0-9]+\.[0-9]+$/.test(v) || 'Must be a number with decimal places.', v => (v > -9999999999999.999999999 && v < 9999999999999.999999999) || 'Must be in the range of +/-9999999999999.999999999.'],
        'number_integer': [v => /^[\d]+$/.test(v) || 'Must be a valid whole number.', v => (v > -9007199254740991 && v < 9007199254740991) || 'Must be in the range of +/-9,007,199,254,740,991.'],
        'list.single_line_text_field': [v => v.length > 0 || 'Must not be empty.', vs => vs.every(v => !!v || v === '') || 'Must all be valid strings.'],
        'list.color': [v => v.length > 0 || 'Must not be empty.', vs => vs.every(v => /^[#][0-9A-Fa-f]{6}$/.test(v)) || 'Must all be hexadecimal numbers.'],
        'list.number_decimal': [v => v.length > 0 || 'Must not be empty.', vs => vs.every(v => /^[-+]?[0-9]+\.[0-9]+$/.test(v)) || 'Must all be valid decimal numbers.'],
        'list.number_integer': [v => v.length > 0 || 'Must not be empty.', vs => vs.every(v => /^[\d]+$/.test(v)) || 'Must all be valid whole numbers.'],
      }
    }
  }
}
</script>