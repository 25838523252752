<template>
  <v-card elevation=4 class="mb-3 rounded-lg" outlined>
    <v-card-text>
      <v-form v-model="valid" @input="$emit('update:changes_valid', valid)">
      <v-row>
        <v-col cols=6>
          <v-text-field label="Name" outlined v-model="product.name" :rules="name_rules" :readonly="!access.update_access"></v-text-field>
        </v-col>

        <v-col cols=6>
          <BrandSelect
            v-model="product.brand_id"
            :brands="brands"
            :access="access"
          ></BrandSelect>
        </v-col>
      </v-row>
      </v-form>

      <CKEditorWrapper v-model="product.description" :access="access"></CKEditorWrapper>
    </v-card-text>
  </v-card>
</template>

<script>
import CKEditorWrapper from '@/components/CKEditorWrapper.vue'
import { brandsProduct } from "@/mixins/brandsMixin.js"
import BrandSelect from '@/components/brands/BrandSelect.vue'
export default {
  mixins: [brandsProduct],
  props: {
    product: Object,
    brands: Array,
    access: Object,
    name_rules: Array,
    brand_rules: Array,
  },
  data() {
    return {
      showAddNewBrand: false,
      descriptionKey: 0,
      valid: true,
      config: {
        btns: [
          ['undo', 'redo'],
          ['formatting'],
          ['strong', 'em', 'del'],
          ['justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull'],
          ['unorderedList', 'orderedList'],
          ['link', 'insertImage', 'noembed'],
          ['horizontalRule'],
          ['superscript', 'subscript'],
          ['viewHTML'],
          ['fullscreen'],
        ]
      }
    }
  },
  watch: {
    '$route.params.id'() {
      this.$nextTick(() => {
        this.descriptionKey++;
      })
    }
  },
  components: {
    BrandSelect,
    CKEditorWrapper,
  }
}
</script>