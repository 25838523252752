<template>
  <v-dialog v-model="show" max-height="100%" @click:outside="$emit('update:show', false)" @keydown.esc="$emit('update:show', false)" @keydown.left="$emit('change', 'back')" @keydown.right="$emit('change', 'forward')">
    <v-card elevation=4 outlined class="rounded-lg" style="overflow: hidden">
      <div class="mr-1 mt-1" style="position: absolute; right: 0; z-index: 100">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" large icon @click="$emit('update:show', false)"><v-icon>mdi-close</v-icon></v-btn>
          </template>
          <span>Close</span>
        </v-tooltip>
      </div>
      <v-carousel :value="selected_image" @change="$emit('update:selected_image', $event)" hide-delimiters :height="windowHeight">
        <v-carousel-item v-for="(image, i) in images" :key="i">
          <v-sheet height="100%">
            <v-row class="fill-height ma-0" align="center" justify="center">
              <v-img :src="image.image_url" height="100%" contain></v-img>
            </v-row>
          </v-sheet>
        </v-carousel-item>
      </v-carousel>
      <div class="mr-2 mb-2" style="position: absolute; bottom: 0; right: 0; z-index: 100">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" fab elevation=0 @click="$emit('download', images[selected_image])"><v-icon>mdi-download</v-icon></v-btn>
          </template>
          <span>Download Image</span>
        </v-tooltip>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    show: Boolean,
    images: Array,
    selected_image: Number,
  },
  data() {
    return {
      windowHeight: window.innerHeight * 80 * 0.01,
    }
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    })
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
  methods: {
    onResize() {
      this.windowHeight = window.innerHeight * 80 * 0.01;
    }
  }
}
</script>