<template>
<v-container fluid @dragover.prevent @drop.prevent>
  <Header 
    :title="`Edit ${product.name}`"
    :subtitle="`Last updated: ${ ('last_updated' in product) ? new Date(`${product.last_updated.replaceAll('-', '/')} UTC`).toLocaleString() : 'never' }`"
    :divider="false"
    :options="[
      {
        'title': 'Rating',
        'icon': 'mdi-percent',
        'disabled': !access.update_access,
        'action': () => {this.showRating = !this.showRating}
      },
      {
        'title': 'Preview',
        'icon': 'mdi-printer-eye',
        'disabled': !access.update_access,
        'hidden': !this.$store.getters.getOrganizationSettings.module_preview || !isChrome,
        'action': () => {this.showPreview = !this.showPreview}
      },
      {
        'title': 'Duplicate',
        'title_disabled': 'Duplicating this product will breach your sku limit.',
        'icon': 'mdi-clipboard-multiple-outline',
        'disabled': !access.create_access || skuLimitMet,
        'action': () => $refs.confirm_dialog.showDialog({ title: 'Duplicating Product', text: 'Are you sure you want to duplicate this product.<br>All local data will be copied (including images).'}).then(() => duplicateProduct({ product_id: product.product_id, name: product.name })).catch(() => {})
      },
      {
        'title': 'Delete',
        'icon': 'mdi-delete',
        'disabled': !access.delete_access,
        'action': () => $refs.confirm_dialog.showDialog({ title: 'Deleting Product', text: 'Are you sure you want to delete this product.<br>Warning! It could be published on an external site.'}).then(() => deleteProduct({ product_id: product.product_id, name: product.name })).catch(() => {})
      },
      {
        'title': 'Publish',
        'icon': 'mdi-send',
        'disabled': !access.update_access || changes,
        'action': () => $refs.publish_dialog.show()
      },
    ]"
  ></Header>

  <!-- Producing Rating Breakdown Dialog -->
  <RatingBreakdownDialog
    :show.sync="showRating"
    :product_id="product.product_id"
    :alwaysRefresh="true"
  ></RatingBreakdownDialog>

  <!-- Forward/Back Navigation Buttons -->
  <ProductForwardBack
    :next_product="next_product"
    :previous_product="previous_product"
    :goToProduct="goToProduct"
  ></ProductForwardBack>

  <!-- Unsaved Changes Bar -->
  <UnsavedChanges
    text="Product Has Unsaved Changes"
    :changes="changes"
    :changes_valid="changes_valid"
    :saveChanges="saveChanges"
    :discardChanges="discardChanges"
  ></UnsavedChanges>

  <!-- Product Data Tabs -->
  <ProductTabs
  :image_moving.sync="image_moving"
  :targetted_variant.sync="targetted_variant"
  :product="product"
  :brands="brands"
  :websites="websites"
  :access="access"
  :changes_valid.sync="changes_valid"
  :name_rules="name_rules"
  :brand_rules="brand_rules"
  :sku_rules="sku_rules"
  :barcode_rules="barcode_rules"
  :variant_type_rules="variant_type_rules"
  :uploadImages="uploadImages"
  :uploadVariantImages="uploadVariantImages"
  :getWebsiteData="getWebsiteData"
  :syncProduct="syncProduct"
  :additionalSkus="additionalSkus"
  ></ProductTabs>

  <ActionDialog ref="unsaved_changes_dialog" title="Unsaved Changes" color="error">
    <template v-slot>
      <span class="error--text" v-html="`Are you sure you want to continue. Any unsaved changes will be lost.`"></span>
    </template>
  </ActionDialog>

  <ActionDialog ref="confirm_dialog" color="error">
    <template #title="{ options }">
      <v-card-title>{{ options.data.title }}</v-card-title>
    </template>

    <template #default="{ options }">
      <span class="error--text" v-html="options.data.text"></span>
    </template>
  </ActionDialog>

  <PublishProductDialog
    ref="publish_dialog"
    :products="[product]"
    :websites="websites"
  ></PublishProductDialog>

  <PreviewDialog
    :show.sync="showPreview"
    :product="product"
    :websites="websites"
  ></PreviewDialog>
</v-container>
</template>

<script>
import { brandsCore } from "@/mixins/brandsMixin.js";
import { websitesCore } from "@/mixins/websitesMixin.js";
import { productCore, productsLogic } from "@/mixins/productsMixin.js";
import { metricsLogic } from "@/mixins/metricsMixin.js";
import Header from "@/components/Header.vue"
import UnsavedChanges from '@/components/UnsavedChanges.vue';
import ActionDialog from '@/components/ActionDialog.vue';
import ProductTabs from "@/components/product/ProductTabs.vue";
import PublishProductDialog from '@/components/PublishProductDialog.vue';
import PreviewDialog from '@/components/products/PreviewDialog.vue';
import ProductForwardBack from '@/components/product/ProductForwardBack.vue';
import RatingBreakdownDialog from '@/components/ratingBreakdown/RatingBreakdownDialog.vue';
export default {
  mixins: [brandsCore, websitesCore, productCore, productsLogic, metricsLogic],
  data() {
    return {
      image_moving: false,
      targetted_variant: null,
      access: this.$store.getters.getAccessLevel[this.$route.path.split('/')[1]],
      changes_valid: true,
      showPreview: false,
      showRating: false,
      name_rules: [v => !!v || 'Name is required'],
      brand_rules: [v => !!v || 'Brand is required'],
      sku_rules: [
        v => !!v || 'SKU is required',
        v => (v || '').length <= 32 || 'SKU can only be 32 characters long'
      ],
      barcode_rules: [
        v => (v || '').length <= 20 || 'Barcode can only be 20 characters long'
      ],
      variant_type_rules: [v => !!v || 'Variant Type is required'],
    };
  },
  components: {
    Header,
    UnsavedChanges,
    ActionDialog,
    ProductTabs,
    PublishProductDialog,
    PreviewDialog,
    ProductForwardBack,
    RatingBreakdownDialog,
  },
  computed: {
    skuLimitMet() {
      return this.$store.getters.getOrganizationSettings.sku_limit < this.$store.getters.getOrganizationState.sku_count + (this.product.variants ? this.product.variants.length : 0);
    },
    isChrome() {
      return navigator.userAgent.indexOf("Chrome") !== -1
    }
  },
  beforeRouteLeave(to, from, next) {
    if (this.changes) {
      this.$refs.unsaved_changes_dialog.showDialog()
        .then(next)
        .catch(() => next(false))
    } else {
      next();
    }
  },
  methods: {
    async getVariantUpdatesRequired(variants, old_variants, new_variant_type) {
      // Initialize arrays for different variants
      let deleted_variants = [];
      let created_variants = [];
      let updated_variants = [];

      // Iterate through each of the variants
      for (var i in variants) {
        let variant = variants[i];
        // If the new variant type is null
        // then the product has been converted
        // to non variant product, nullify the
        // variant names
        if (new_variant_type === null) {
          variant.variant_name = null;
        }
        // Check if the variant has been deleted
        if ("is_deleted" in variant) {
          // Add Index to Variant to delete
          // so we can delete/splice by index
          variant.index = i;
          deleted_variants.push(variant);
        }
        // Check if the variant has been created
        else if (old_variants[i] === undefined) {
          created_variants.push(variant);
        }
        // Check if the variant has been updated
        else {
          // Iterate through each of the variant keys
          for (var key in variant) {
            // For array variables
            if (Array.isArray(variant[key])) {
              for (var j in variant[key]) {
                if (JSON.stringify(variant[key][j]) !== JSON.stringify(old_variants[i][key][j])) {
                  // Initialize object for variant changes
                  if (!variant_updates) {
                    var variant_updates = { variant_id: variant.variant_id };
                  }
                  // If variant changes already exists but changed array does not
                  if (!(key in variant_updates)) {
                    variant_updates[key] = [];
                  }
                  variant_updates[key].push(variant[key][j]);
                }
              }
            } 
            // For non-array variables
            else if (variant[key] !== old_variants[i][key]) {
              // Initialize object for variant changes
              if (!variant_updates) {
                variant_updates = { variant_id: variant.variant_id };
              }
              variant_updates[key] = variant[key];
            }
          }
          // If current variant updated, push to array and reset
          if (variant_updates) {
            // Always send through the variant type 
            // if there's an update to adjust the
            // external names dynamically
            variant_updates['variant_type'] = new_variant_type;
            updated_variants.push(variant_updates);
            variant_updates = undefined;
          }
        }
      }
      return { deleted_variants, created_variants, updated_variants };
    },
    async getImagesUpdatesRequired(images) {
      let order = 1;
      let deleted_images = images.filter(image => image.is_deleted === true);
      let updated_images = images.filter(image => image.is_deleted === undefined).map(image => {image.image_order = order++; return image;});
      return { deleted_images, updated_images };
    },
    async getMetafieldUpdatesRequired(new_metafields, old_metafields, deleted_website_metafields, changes, website, product_id, variant_id) {
      // Initialize a local deleted metafields array for this website
      const deleted_metafields = [];

      // Iterate through each of the metafields
      for (let i in new_metafields) {
        // Test if this metafield has been deleted
        if ('is_deleted' in new_metafields[i]) {
          deleted_metafields.push(new_metafields[i]["metafield_id"]);
        }
        // Test if this metafield has changed
        else if (JSON.stringify(new_metafields[i]) !== JSON.stringify(old_metafields[i])) {
          // Initialize the metafields changes if neccessary
          if (!("metafields" in changes)) changes["metafields"] = [];

          // Add the metafield to the product changes to be updated
          changes["metafields"].push(new_metafields[i]);
        }
      }

      // If any metafields were deleted, add them to the website array
      if (deleted_metafields.length) {
        deleted_website_metafields.push({ product_id: product_id, variant_id: variant_id, website: website, metafields: deleted_metafields });
      }
    },
    async getWebsiteOptionUpdatesRequired(new_website_options, old_website_options) {
      // Initialize arrays for different website options
      const updated_website_products = [];
      const updated_website_variants = [];
      const created_website_tags = [];
      const deleted_product_metafields = [];
      const deleted_variant_metafields = [];

      // Iterate through each website's options
      for (var website_id in new_website_options) {
        const website = this.websites.find(x => x.website_id === parseInt(website_id));
        const new_options = new_website_options[website_id];
        const old_options = old_website_options[website_id];
        const product_changes = {};

        // Switch on website type to get updates required
        switch (website.type) {
          case 'B2BWave':
            // Test if any options have changed
            if (JSON.stringify(new_options) !== JSON.stringify(old_options)) {
              updated_website_products.push({ website: website, changes: new_options });
            }
            break;
          case 'Shopify':
            // Iterate through each of the website option keys
            for (var option in new_options) {
              if (option === 'created_tags') {
                // Test if any tags have been created
                if (new_options[option].length) {
                  const created_tags = [];

                  // Iterate throuch each created tag
                  for (let tag of new_options[option]) {
                    // Add the new tag to the local created tags array
                    created_tags.push(tag);

                    // Initialize the tags changes if neccessary
                    if (!("tags" in product_changes)) product_changes["tags"] = new_options.tags;

                    // Add the new tag to the product changes to be updated
                    product_changes['tags'].push(tag);
                  }

                  // Add the tags created for this website to the created tags array
                  created_website_tags.push({ website: website, tags: created_tags });
                }
              } else if (option === 'metafields') {
                // Get the product metafield updates
                await this.getMetafieldUpdatesRequired(new_options[option], old_options[option], deleted_product_metafields, product_changes, website, new_options["product_id"]);
              } else if (option === 'variants') {
                // Iterate through each of the product's variants
                for (let i in new_options[option]) {
                  const variant_changes = {};

                  // Iterate through each of the variant's keys
                  for (let variant_option in new_options[option][i]) {
                    if (variant_option === 'metafields') {
                      // Get the variant metafield updates
                      await this.getMetafieldUpdatesRequired(new_options[option][i][variant_option], old_options[option][i][variant_option], deleted_variant_metafields, variant_changes, website, new_options["product_id"], new_options[option][i]["variant_id"]);
                    } else {
                      // Test if this variant option has changed
                      if (JSON.stringify(new_options[option][i][variant_option]) !== JSON.stringify(old_options[option][i][variant_option])) {
                        variant_changes[option] = new_options[option][i][variant_option];
                      }
                    }
                  }

                  // If there are changes from this website, add them to the appriopriate array
                  if (Object.keys(variant_changes).length) {
                    updated_website_variants.push({ product_id: new_options["product_id"], variant_id: new_options[option][i]["variant_id"], website: website, changes: variant_changes });
                  }
                }
              } else {
                // Test if this option has changed
                if (JSON.stringify(new_options[option]) !== JSON.stringify(old_options[option])) {
                  product_changes[option] = new_options[option];
                }
              }
            }

            // If there are changes from this website, add them to the appriopriate array
            if (Object.keys(product_changes).length) {
              updated_website_products.push({ product_id: new_options["product_id"], website: website, changes: product_changes });
            }
            break;
        }
      }
      return { updated_website_products, updated_website_variants, created_website_tags, deleted_product_metafields, deleted_variant_metafields };
    },
    async getUpdatesRequired() {
      // Initialize values for getting required updates
      let old_product = this.$store.state.products.oldData.product;
      let updates = {};
      
      // Iterate through each of the product object's keys
      for (var key in this.product) {
        // Switch on the key to determine how to process updates required
        switch (key) {
          case 'variants':
            // Check for changes to the variants
            var { deleted_variants, created_variants, updated_variants } = await this.getVariantUpdatesRequired(this.product[key], old_product.variants, this.$store.state.products.newData.product.variant_type);

            // Conditionally build update object based on the updates returned
            if (deleted_variants.length > 0) {
              updates["deleted_variants"] = deleted_variants;
            }
            if (created_variants.length > 0) {
              updates["created_variants"] = created_variants;
            }
            if (updated_variants.length > 0) {
              updates["updated_variants"] = updated_variants;
            }
            break;
          case 'images':
            // Check for changes to the images
            var { deleted_images, updated_images } = await this.getImagesUpdatesRequired(this.product[key]);

            // Conditionally build update object based on the updates returned
            if (deleted_images.length > 0) {
              updates["deleted_images"] = deleted_images;
            }
            if (JSON.stringify(updated_images) !== JSON.stringify(old_product.images)) {
              updates["updated_images"] = updated_images;
            }
            break;
          case 'website_options':
            // Check for changes to the website options
            var { updated_website_products, updated_website_variants, created_website_tags, deleted_product_metafields, deleted_variant_metafields } = await this.getWebsiteOptionUpdatesRequired(this.product[key], old_product.website_options);

            // Conditionally build update object based on the updates returned
            if (updated_website_products.length > 0) {
              updates["updated_website_products"] = updated_website_products;
            }
            if (updated_website_variants.length > 0) {
              updates["updated_website_variants"] = updated_website_variants;
            }
            if (created_website_tags.length > 0) {
              updates["created_website_tags"] = created_website_tags;
            }
            if (deleted_product_metafields.length > 0) {
              updates["deleted_product_metafields"] = deleted_product_metafields;
            }
            if (deleted_variant_metafields.length > 0) {
              updates["deleted_variant_metafields"] = deleted_variant_metafields;
            }
            break;
          default:
            // Check for changes to the core product
            if (this.product[key] !== old_product[key]) {
              // If product object not created, initialize
              if (!("product" in updates)) {
                updates["product"] = { product_id: this.product.product_id };
              }
              updates["product"][key] = this.product[key];
            }
        }
      }
      // Return the dynamically built updates payload
      return updates;
    },
    async saveChanges() {
      this.$store.dispatch("performingUpdate", true);
      let updates = await this.getUpdatesRequired();

      // Update the product details if required
      if ("product" in updates) {
        await this.updateProduct(updates.product);
      }

      // Delete removed variants if required
      if ("deleted_variants" in updates) {
        await this.deleteVariants(updates.deleted_variants);
      }

      // Update the variant details if required
      if ("updated_variants" in updates) {
        await this.updateVariants(updates.updated_variants);
      }

      // Then update the image order if required
      if ("updated_images" in updates) {
        await this.updateImages(updates.updated_images);
      }

      // Update the website products if required
      if ("updated_website_products" in updates) {
        await this.updateWebsiteProducts(updates.updated_website_products);
      }

      // Update the website variants if required
      if ("updated_website_variants" in updates) {
        await this.updateWebsiteVariants(updates.updated_website_variants);
      }

      // Upload the new website tags if required
      if ("created_website_tags" in updates) {
        await this.uploadWebsiteTags(updates.created_website_tags);
      }

      // Delete the removed metafields if required
      if ("deleted_product_metafields" in updates) {
        await this.deleteProductMetafields(updates.deleted_product_metafields);
      }
      if ("deleted_variant_metafields" in updates) {
        await this.deleteVariantMetafields(updates.deleted_variant_metafields);
      }

      // Upload new variants if required
      if ("created_variants" in updates) {
        await this.uploadVariants(updates.created_variants);
      }

      // Delete removed images if required
      if ("deleted_images" in updates) {
        await this.deleteImages(updates.deleted_images);
      }

      this.$store.dispatch("performingUpdate", false);

      // After changes, generate or update product rating
      this.updateProductRating(this.product.product_id);

      // Checks to see if updated field is a website update
      const isWebsiteUpdate = (x) => ["updated_website_products", "updated_website_variants", "created_website_tags", "deleted_product_metafields", "deleted_variant_metafields"].includes(x)

      // Check all updates to see if only updating website options
      // if not, then update the product last updated field.
      // Required as product will show on unpublished changes
      // report for all websites if only website change is made
      if (!Object.keys(updates).every(isWebsiteUpdate)) {
        this.updateProductLastUpdated(this.product.product_id);
      }

      // Check to see if at least one update is a website update
      if (Object.keys(updates).some(isWebsiteUpdate)) {
        // Refetch the product website options
        this.getWebsiteData(this.product.product_id);
      }
    }
  }
};
</script>