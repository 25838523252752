import { render, staticRenderFns } from "./ProductImages.vue?vue&type=template&id=201162e7&scoped=true&"
import script from "./ProductImages.vue?vue&type=script&lang=js&"
export * from "./ProductImages.vue?vue&type=script&lang=js&"
import style0 from "./ProductImages.vue?vue&type=style&index=0&id=201162e7&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "201162e7",
  null
  
)

export default component.exports