<template>
  <v-dialog v-model="show" max-width="700" @click:outside="$emit('update:show', false)" @keydown.esc="$emit('update:show', false)">
    <v-card>
      <v-form ref="form">
        <v-container>
          <v-row justify="center">
            <v-card-title v-if="isConvert" class="headline">Convert to Variant Product</v-card-title>
            <v-card-title v-else class="headline">Add Product Variant</v-card-title>
          </v-row>
          <v-container justify="space-around">
            <v-row v-if="isConvert">
              <v-combobox
                v-model="variant.variant_type"
                :rules="variant_type_rules"
                :items="['Colour', 'Size', 'Material', 'Style', 'Device']"
                label="Variant Type"
                required
                clearable
              ></v-combobox>
            </v-row>
            <v-row>
              <v-text-field
                v-model="variant.variant_name"
                :rules="[v => !!v || 'Variant name is required']"
                label="Name"
                placeholder="Green"
                required
              ></v-text-field>
            </v-row>
            <v-row>
              <v-text-field
              v-model="variant.sku"
              :rules="sku_rules"
              label="SKU"
              placeholder="123456"
              required
            ></v-text-field>
            </v-row>
            <v-row>
              <v-text-field
              v-model="variant.barcode"
              :rules="barcode_rules"
              label="Barcode"
              placeholder="9498756454"
              required
            ></v-text-field>
            </v-row>
          </v-container>
          <v-row justify="center">
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn v-if="isConvert" color="blue darken-1" text @click="convertToVariant()">Convert</v-btn>
              <v-btn v-else color="blue darken-1" text @click="addVariant()">Confirm</v-btn>
            </v-card-actions>
          </v-row>
        </v-container>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    variant: Object,
    isConvert: Boolean,
    show: Boolean,
  },
  data() {
    return {
      variant_type_rules: [v => !!v || 'Variant Type is required'],
      sku_rules: [
        v => !!v || 'SKU is required',
        v => (v || '').length <= 32 || 'SKU can only be 32 characters long'
      ],
      barcode_rules: [v => (v || '').length <= 20 || 'Barcode can only be 20 characters long']
    };
  },
  watch: {
    show: function(val) {
      // Reset validation on form open
      if (val) {
        this.$nextTick(() => {
          this.$refs.form.resetValidation();
        });
      }
    }
  },
  methods: {
    convertToVariant() {
      if (this.$refs.form.validate()) {
        this.$emit('update:show', false);
        this.$store.commit("setProductVariantType", this.variant.variant_type);
      }
    },
    addVariant() {
      if (this.$refs.form.validate()) {
        this.$emit('update:show', false);
        this.$store.commit("addVariant", this.variant);
        this.$emit('reset');
        this.$refs.form.resetValidation();
      }
    }
  }
};
</script>
