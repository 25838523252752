<template>
  <v-dialog v-model="show" max-width="700" @click:outside="$emit('update:show', false)" @keydown.esc="$emit('update:show', false)">
    <v-card>
      <v-form ref="tag_card" @submit="(e) => e.preventDefault()">
        <v-container>
          <v-row justify="center">
            <v-card-title class="headline">Add New Tag</v-card-title>
          </v-row>
          <v-container justify="space-around">
            <v-row>
              <v-text-field v-model="tag.name" label="Name" :rules="[v => !!v || 'This field is required']" v-on:keyup.enter="validate()"></v-text-field>
            </v-row>
            <v-row justify="center">
              <v-btn color="success" @click="validate()">Confirm</v-btn>
            </v-row>
          </v-container>
        </v-container>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    show: Boolean,
    created_tags: Array,
  },
  data() {
    return {
      tag: {}
    }
  },
  watch: {
    show(val) {
      if (val && !this.tag.name) {
        this.$nextTick(() => {
          this.$refs.tag_card.resetValidation();
        })
      }
    }
  },
  methods: {
    validate() {
      if (this.$refs.tag_card.validate()) {
        // Push new tag to created tags array
        this.created_tags.push(this.tag.name);
        
        // Close the dialog and reset the tag state
        this.$emit('update:show', false);
        this.tag = {};
        this.$refs.tag_card.resetValidation();
      }
    }
  }
};
</script>
