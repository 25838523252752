<template>
  <v-dialog v-model="show" max-width="500px" @click:outside="$emit('update:show', false)" @keydown.esc="$emit('update:show', false)">
    <v-card>
      <v-card-title>
        Preview Options
      </v-card-title>
      <v-divider/>
      <v-form ref="previewProductData">
        <v-card-text style="padding: 8px 16px 0px 16px;">
          <v-container style="text-align: center; padding-bottom: 0px; padding-top: 0px">
            Website
            <v-select
              :items="websites"
              :rules="[v => !!v || 'Website is required']"
              label="Website"
              outlined
              v-model="preview_options.site"
              item-text="name"
              item-value="type"
            ></v-select>
          </v-container>
        </v-card-text>
        <v-divider/>
        <v-card-text style="padding: 8px 16px 0px 16px;">
          <v-container style="text-align: center; padding-bottom: 0px; padding-top: 0px">
            Variant
            <v-select
              :items="product.variants"
              :rules="[v => !!v || 'Variant is required']"
              label="Variant"
              outlined
              v-model="preview_options.variant"
              item-text="variant_name"
              item-value="variant_id"
            >
              <template slot="selection" slot-scope="data">
                {{ (data.item.variant_name ? data.item.variant_name : data.item.sku ) }}
              </template>
              <template slot="item" slot-scope="data">
                {{ (data.item.variant_name ? data.item.variant_name : data.item.sku ) }}
              </template>
            </v-select>
          </v-container>
        </v-card-text>
        <v-divider/>
        <v-card-text style="padding: 8px 16px 16px 16px;">
          <v-container style="text-align: center; padding-bottom: 0px; padding-top: 0px">
            Name
            <v-select
              :items="['Global Name', 'Localized Name']"
              label="Name"
              outlined
              v-model="preview_options.name"
              hide-details
            ></v-select>
          </v-container>
          <v-container style="text-align: center; padding-bottom: 0px; padding-top: 0px">
            Description
            <v-select
              :items="['Global Description', 'Localized Description']"
              label="Description"
              outlined
              v-model="preview_options.description"
              hide-details
            ></v-select>
          </v-container>
          <v-container style="text-align: center; padding-bottom: 0px; padding-top: 0px">
            Meta Description
            <v-select
              :items="['No Meta Description', 'Localized Meta Description']"
              label="Meta Description"
              outlined
              v-model="preview_options.meta_description"
              hide-details
            ></v-select>
          </v-container>
        </v-card-text>
      </v-form>

      <v-container id="previewExtensionConfirmationBox" style="display: none;">
        <v-alert
          :v-model="true"
          dismissible
          color="green"
          border="left"
          elevation="2"
          colored-border
          icon="mdi-check-circle-outline"
          max-width="500px"
          hide-details
        >
          Preview Data sent to Extension Successfully!
        </v-alert>
      </v-container>

      <v-divider/>
      <v-card-actions class="justify-center" style="padding-top: 0">
        <v-btn color="error" text @click="$emit('update:show', false)">
          Close
        </v-btn>
        <v-btn id="previewProductBtn" color="success" text @click="previewProduct()">
          Preview
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    show: Boolean,
    product: Object,
    websites: Array,
  },
  data() {
    return {
      preview_options: {
        site: '',
        name: 'Global Name',
        description: 'Global Description',
        meta_description: 'No Meta Description',
        variant: ''
      }
    }
  },
  methods: {
    async previewProduct() {
      // Ensure required fields are filled
      if (this.$refs.previewProductData.validate()) {
        // Initialize URL for get request
        let url = `${process.env.VUE_APP_API_BASE_URL}/products/${this.$route.params.id}/variants/${this.preview_options.variant}/preview`;
        let param_before = false;

        // Iterate through each of the preview options
        // and add to URL as query parameters
        for (var key in this.preview_options) {
          url += (param_before ? '&' : '?') + `${key}=${this.preview_options[key]}`;
          param_before = true;
        }
        // Send options to backend endpoint to return required data
        let result = await this.$axios.get(url)
          .catch(err => {
            console.log(err);
          });

        let payload = {
          name: result.data.name,
          brand: result.data.brand,
          description: result.data.description,
          sku: result.data.sku,
          barcode: result.data.barcode,
          variant_name: result.data.variant_name,
          variant_type: result.data.variant_type,
          images: result.data.images
        }

        // Create a new custom event called previewProduct
        const preview_product_event = new CustomEvent('previewProduct', { detail: payload });
  
        // Dispatch the event to the main document body
        // This is the event that the extension listens for
        document.body.dispatchEvent(preview_product_event);
      }
    }
  }
}
</script>