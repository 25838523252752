<template>
  <v-row>
    <!-- Left-hand side column -->
    <v-col cols=3>
      <!-- Selected Variant Details -->
      <v-card elevation=4 outlined class="rounded-lg mb-3">
        <v-card-title>Selected Variant</v-card-title>
        <v-divider/>
        <v-card-text>
          <v-row dense>
            <v-col cols=auto>
              <v-img :src="variantImage(selected_variant)" gradient="to bottom, rgba(0,0,0,.03), rgba(0,0,0,.05)" height="70px" width="70px" class="image-border" contain/>
            </v-col>
            <v-col cols=auto>
              <p class="mt-n0 mb-1" v-if="selected_variant.variant_name">Name: <v-chip small outlined>{{ selected_variant.variant_name }}</v-chip></p>
              <p class="mb-1">SKU: <v-chip small outlined>{{ selected_variant.sku }}</v-chip></p>
              <p class="mb-n1" v-if="selected_variant.barcode">Barcode: <v-chip small outlined>{{ selected_variant.barcode }}</v-chip></p>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider/>
        <v-card-text>
          <v-select
            v-model="selected_variant"
            :items="product.variants"
            label="Select Variant"
            outlined
            hide-details
            item-text="sku"
            item-value="variant_id"
            return-object
            height="56px"
            single-line
            style="border-radius: 0.5rem;"
          >
            <template v-slot:selection="{ item }">
              {{ item.variant_name ? item.variant_name : item.sku }}
            </template>
            <template v-slot:item="{ item }">
              <v-img :src="variantImage(item.variant_id)" gradient="to bottom, rgba(0,0,0,.03), rgba(0,0,0,.05)" height="45px" width="45px" max-width="45px" style="margin-right: 8px;" class="image-border" contain/>
              {{ item.variant_name ? item.variant_name : item.sku }}
            </template>
          </v-select>
        </v-card-text>
      </v-card>

      <!-- Subtabs -->
      <v-card elevation=4 outlined class="rounded-lg mt-3">
        <v-card-title>Subtabs</v-card-title>
        <v-divider/>
        <v-card-text style="padding: 4px">
          <v-list nav>
            <v-list-item-group v-model="subtab_selection" mandatory color="primary">
              <v-list-item v-for="subtab in subtabs" :key="subtab.title" link>
              <v-list-item-icon>
                <v-icon>{{ subtab.icon }}</v-icon>
              </v-list-item-icon>
                <v-list-item-title>{{ subtab.title }}</v-list-item-title>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card-text>
      </v-card>
    </v-col>

    <!-- Right-hand side column -->
    <v-col cols=9>
      <v-form @input="$emit('update:changes_valid', $event)">
        <!-- Pricing Data Card -->
        <v-expand-transition>
          <v-card elevation=4 outlined class="rounded-lg mb-3" v-show="subtab_selection === 0 || subtab_selection === 1">
            <v-card-title>{{ subtabs[1].title }}</v-card-title>
            <v-divider/>
            <v-card-text v-for="price in selected_variant.prices" :key="price.priceLevel_id">
              <v-text-field v-model.number="price.price" :label="price.name" prefix="$" :readonly="!access.update_access" :rules="number_rules" outlined type=number hide-details=auto></v-text-field>
            </v-card-text>
          </v-card>
        </v-expand-transition>

        <!-- Inventory Data Card -->
        <!-- <v-expand-transition>
          <v-card elevation=4 outlined class="rounded-lg mb-3" v-show="subtab_selection === 0 || subtab_selection === 2">
            <v-card-title>{{ subtabs[2].title }}</v-card-title>
            <v-divider/>
            <v-card-text>
              Coming soon...
            </v-card-text>
          </v-card>
        </v-expand-transition> -->
      </v-form>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    product: Object,
    access: Object,
  },
  data() {
    return {
      selected_variant: this.product.variants[0],
      subtab_selection: 0,
      subtabs: [
        { title: 'All Options', icon: 'mdi-all-inclusive'},
        { title: 'Pricing', icon: 'mdi-currency-usd' },
        // { title: 'Inventory', icon: 'mdi-warehouse' },
      ],
      number_rules: [v => (!!v || v===0) || 'Must be a valid number.', v => v.toString().split('.')[0].length <= 8 || 'Number too large.'],
    }
  },
  methods: {
    variantImage(variant_id) {
      let images = this.product.images.filter(image => image.variant_id === variant_id);
      if (images.length > 0 && variant_id !== undefined) {
        return images[0].image_url;
      } else {
        return process.env.VUE_APP_NO_IMAGE_IMG;
      }
    }
  }
}
</script>