<template>
  <v-row>
    <!-- Left-hand side column -->
    <v-col cols=3>
      <!-- Selected Variant Details -->
      <v-card elevation=4 outlined class="rounded-lg mb-3">
        <v-card-title>
          Selected Variant
          <v-spacer/>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" @click="syncProduct({ product_id: product.product_id, product_name: product.name, website_id: website.website_id, website_name: website.name, website_type: website.type })">mdi-autorenew</v-icon>
            </template>
            <span>Sync Product</span>
          </v-tooltip>
        </v-card-title>
        <v-divider/>
        <v-card-text>
          <v-row dense>
            <v-col cols=auto>
              <v-img :src="variantImage(variant_selection)" gradient="to bottom, rgba(0,0,0,.03), rgba(0,0,0,.05)" height="70px" width="70px" class="image-border" contain/>
            </v-col>
            <v-col cols=auto>
              <p class="mt-n1 mb-1">Status: <v-chip small :color="selectedVariant.external_product_id ? `success` : `error`">{{ selectedVariant.external_product_id ? 'Published' : 'Unpublished' }} {{ ('last_published' in selectedVariant && selectedVariant.last_published !== null) ? new Date(`${selectedVariant.last_published.replaceAll('-', '/')} UTC`).toLocaleString() : '' }}</v-chip></p>
              <p class="mb-1" v-if="selectedVariant.external_product_id">External ID: <v-chip small outlined @click="goToWebsiteProduct">{{ selectedVariant.external_product_id }}</v-chip></p>
              <p class="mb-0">Last Updated: <v-chip small outlined>{{ ('last_updated' in selectedVariant) ? new Date(`${selectedVariant.last_updated.replaceAll('-', '/')} UTC`).toLocaleString() : 'Never' }}</v-chip></p>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <p class="mb-0">SKU: <v-chip small color="primary" outlined>{{ product.variants[product.variants.findIndex(x => x.variant_id === selectedVariant.variant_id)].sku }}</v-chip></p>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider/>
        <v-card-text>
          <v-select
            v-model="variant_selection"
            :items="product.variants"
            label="Select Variant"
            outlined
            hide-details
            item-text="sku"
            item-value="variant_id"
            height="56px"
            single-line
            style="border-radius: 0.5rem;"
          >
            <template v-slot:selection>
              {{ selectedVariant.name }}
            </template>
            <template v-slot:item="{ item }">
              <v-img :src="variantImage(item.variant_id)" gradient="to bottom, rgba(0,0,0,.03), rgba(0,0,0,.05)" height="45px" width="45px" max-width="45px" style="margin-right: 8px;" class="image-border" contain/>
              {{ product.website_options[website.website_id][product.website_options[website.website_id].findIndex(x => x.variant_id === item.variant_id)].name }}
            </template>
          </v-select>
        </v-card-text>
      </v-card>

      <!-- Subtabs -->
      <v-card elevation=4 outlined class="rounded-lg">
        <v-card-title>Subtabs</v-card-title>
        <v-divider/>
        <v-card-text style="padding: 4px">
          <v-list nav>
            <v-list-item-group v-model="subtab_selection" mandatory color="primary">
              <v-list-item v-for="subtab in subtabs" :key="subtab.title" link>
              <v-list-item-icon>
                <v-icon>{{ subtab.icon }}</v-icon>
              </v-list-item-icon>
                <v-list-item-title>{{ subtab.title }}</v-list-item-title>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card-text>
      </v-card>
    </v-col>

    <!-- Right-hand side column -->
    <v-col justify="center" cols=9>
      <v-form v-model="valid" @input="$emit('update:changes_valid', valid)">
        <!-- Localized Information Card -->
        <v-expand-transition>
          <v-card elevation=4 outlined class="rounded-lg mb-3" v-show="subtab_selection === 0 || subtab_selection === 1">
            <v-card-title>Localized Information</v-card-title>
            <v-card-subtitle>
                This content is independent of the main product content and will be used soley for the <b>{{ website.name }}</b> site.
            </v-card-subtitle>
            <v-switch
              v-model="selectedVariant.use_custom_fields"
              label="Use Localized Information"
              inset
              style="position: absolute; right: 0; top: 0; padding-right: 30px;"
              @change="updateCustomFields"
            ></v-switch>
            <v-divider/>

            <v-card-text>
              <v-text-field v-model="selectedVariant.name" :rules="name_rules" :label="`${website.type} Name`" required :readonly="!access.update_access" outlined hide-details></v-text-field>
            </v-card-text>

            <v-divider/>

            <v-card-text>
              <h4>{{ website.type}} Description</h4>
              <CKEditorWrapper v-model="selectedVariant.description" :access="access" :disabled="!selectedVariant.use_custom_fields"></CKEditorWrapper>
              <v-textarea
                v-model="selectedVariant.meta_description"
                label="Meta Description"
                :readonly="!access.update_access"
                :disabled="!selectedVariant.use_custom_fields"
                outlined
                hide-details
                style="padding-top: 8px"
              ></v-textarea>
            </v-card-text>
          </v-card>
        </v-expand-transition>

        <!-- Categories Card -->
        <v-expand-transition>
          <v-card elevation=4 outlined class="rounded-lg mb-3" v-show="subtab_selection === 0 || subtab_selection === 2">
            <v-card-title>Categories</v-card-title>
            <v-divider/>
            <v-card-text :style="valid ? `` : `padding-bottom: 4px`">
              <v-autocomplete
              v-model="selectedVariant.categories"
              :items="website.options"
              :rules="category_rules"
              item-value="category_id"
              item-text="category_name"
              label="Select Categories"
              :clearable="access.update_access"
              outlined
              chips
              small-chips
              deletable-chips
              multiple
              :readonly="!access.update_access"
              :hide-details="valid"
              no-data-text="No categories found! Try syncing your site."
              >
                <template v-slot:message="{ message }" style="margin: 0">
                  {{ message }}
                </template>
              </v-autocomplete>
            </v-card-text>
          </v-card>
        </v-expand-transition>

        <!-- Visibility Data Card -->
        <v-expand-transition>
          <v-card elevation=4 outlined class="rounded-lg mb-3" v-show="subtab_selection === 0 || subtab_selection === 3">
            <v-card-title>Visibility</v-card-title>
            <v-divider/>
              <v-card-text>
                <v-select
                v-model="selectedVariant.available"
                :items="[{status: true, name: 'Active'}, {status: false, name: 'Inactive'}]"
                :rules="active_rules"
                item-value="status"
                item-text="name"
                label="Select Visibility"
                outlined
                :readonly="!access.update_access"
                hide-details
                ></v-select>
            </v-card-text>
          </v-card>
        </v-expand-transition>
      </v-form>
    </v-col>
  </v-row>
</template>

<script>
import CKEditorWrapper from '@/components/CKEditorWrapper.vue'
export default {
  components: {
    CKEditorWrapper
  },
  props: {
    product: Object,
    website: Object,
    access: Object,
    syncProduct: Function,
  },
  data() {
    return {
      variant_selection: this.product.variants[0].variant_id,
      subtab_selection: 0,
      subtabs: [
        { title: 'All Options', icon: 'mdi-all-inclusive'} ,
        { title: 'Localized Information', icon: 'mdi-earth' },
        { title: 'Categories', icon: 'mdi-tag' },
        { title: 'Visibility', icon: 'mdi-eye' },
      ],
      category_rules: [v => v.length >= 1 || 'At least one category is required'],
      active_rules: [v => v !== null || `Product status is required`],
      name_rules: [ v => !!v || `Product name is required`],
      valid: true,
      config: {
        btns: [
          ['undo', 'redo'],
          ['formatting'],
          ['strong', 'em', 'del'],
          ['justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull'],
          ['unorderedList', 'orderedList'],
          ['link', 'insertImage', 'noembed'],
          ['horizontalRule'],
          ['superscript', 'subscript'],
          ['viewHTML'],
          ['fullscreen'],
        ]
      }
    }
  },
  methods: {
    goToWebsiteProduct() {
      window.open(`${this.website.url.replace('api','products/')}${this.selectedVariant.external_product_id}/edit`,'_newtab');
    },
    updateCustomFields(use_localized) {
      for (var variant of this.product.website_options[this.website.website_id]) {
        variant.use_custom_fields = use_localized;
        // No need to populate name, this should Automatically
        // be populated on a B2B Wave Product
        if (use_localized) {
          // Populate with description
          if (variant.description === '') {
            variant.description = this.product.description;
          }
        }
      }
    },
    variantImage(variant_id) {
      let images = this.product.images.filter(image => image.variant_id === variant_id);
      if (images.length > 0 && variant_id !== undefined) {
        return images[0].image_url;
      } else {
        return process.env.VUE_APP_NO_IMAGE_IMG;
      }
    }
  },
  computed: {
    selectedVariant() {
      return this.product.website_options[this.website.website_id][this.product.variants.findIndex(x => x.variant_id === this.variant_selection)];
    }
  }
}
</script>