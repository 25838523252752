<template>
  <!-- Multi Variant Card -->
  <div v-if="!single_variant">
  <v-card v-if="!variant.is_deleted" outlined elevation=2 style="border-radius: 8px" @drop="onDrop" @dragenter="onDragEnter" @dragleave="onDragLeave" :class="{ highlight: dragging && targetted_variant === `variant_${variant.variant_id}` }">

    <!-- Dialog for variant images -->
    <ProductVariantImagesDialog
      :show.sync="showVariantImages"
      :variant_images="variant_images"
      :droppedImages.sync="droppedImages"
      :variant_id="variant.variant_id"
      :uploadVariantImages="uploadVariantImages"
      :access="access"
      v-on="$listeners"
    ></ProductVariantImagesDialog>

    <v-card-text>
      <v-form v-model="valid" @input="$emit('update:changes_valid', valid)">
      <v-row style="overflow-x: auto; flex-wrap: nowrap;" align="center">
        <v-col cols=auto>
          <v-img @click="showVariantImages = !showVariantImages && variant.variant_id !== null" class="image-border imageCard" :src="variant_images[0].image_url" gradient="to bottom, rgba(0,0,0,.03), rgba(0,0,0,.05)" height="70px" width="70px" style="border-radius: 8px" contain></v-img>
        </v-col>
        <v-col cols=auto>
          <v-text-field v-model="variant.variant_name" label="Name" :rules="[v => !!v || 'Variant name is required']" required outlined hide-details :readonly="!access.update_access">
            <template v-slot:append>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on" @click="copyToClipboard(variant.variant_name)">{{ copy_value === variant.variant_name ? copied_icon : copy_icon }}</v-icon>
                </template>
                <span>{{ copy_value === variant.variant_name ? copied_text : copy_text }}</span>
              </v-tooltip>
            </template>
          </v-text-field>
        </v-col>
        <v-col cols=auto>
          <v-text-field v-model="variant.sku" label="Sku" :rules="sku_rules" required outlined hide-details :readonly="!access.update_access">
            <template v-slot:append>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on" @click="copyToClipboard(variant.sku)">{{ copy_value === variant.sku ? copied_icon : copy_icon }}</v-icon>
                </template>
                <span>{{ copy_value === variant.sku ? copied_text : copy_text }}</span>
              </v-tooltip>
            </template>
          </v-text-field>
        </v-col>
        <v-col cols=auto>
          <v-text-field v-model="variant.barcode" label="Barcode" :rules="barcode_rules" required outlined hide-details :readonly="!access.update_access">
            <template v-slot:append>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on" @click="copyToClipboard(variant.barcode)">{{ copy_value === variant.barcode ? copied_icon : copy_icon }}</v-icon>
                </template>
                <span>{{ copy_value === variant.barcode ? copied_text : copy_text }}</span>
              </v-tooltip>
            </template>
          </v-text-field>
        </v-col>
        <v-col cols=auto v-if="access.update_access">
          <v-row no-gutters>
            <v-col cols=6>
              <v-tooltip bottom v-if="single_convert">
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" icon @click="convertToSingle"><v-icon>mdi-trash-can-outline</v-icon></v-btn>
                </template>
                <span>Convert to Single Variant Product</span>
              </v-tooltip>
              <v-tooltip bottom v-else>
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" icon @click="removeVariant(variantIndex)"><v-icon>mdi-trash-can-outline</v-icon></v-btn>
                </template>
                <span>Remove Variant</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      </v-form>
    </v-card-text>
  </v-card>
  </div>
  <!-- Single Variant Card -->
  <div v-else>
  <v-card v-if="!variant.is_deleted" flat style="border-radius: 8px">
    <v-card-text>
      <v-form v-model="valid" @input="$emit('update:changes_valid', valid)">
        <v-row style="overflow-x: auto; flex-wrap: nowrap;" align="center">
          <v-col cols=6>
            <v-text-field v-model="variant.sku" label="Sku" :rules="sku_rules" required outlined hide-details :readonly="!access.update_access">
              <template v-slot:append>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" @click="copyToClipboard(variant.sku)">{{ copy_value === variant.sku ? copied_icon : copy_icon }}</v-icon>
                  </template>
                  <span>{{ copy_value === variant.sku ? copied_text : copy_text }}</span>
                </v-tooltip>
              </template>
            </v-text-field>
          </v-col>
          <v-col cols=6>
            <v-text-field v-model="variant.barcode" label="Barcode" :rules="barcode_rules" required outlined hide-details :readonly="!access.update_access">
              <template v-slot:append>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" @click="copyToClipboard(variant.barcode)">{{ copy_value === variant.barcode ? copied_icon : copy_icon }}</v-icon>
                  </template>
                  <span>{{ copy_value === variant.barcode ? copied_text : copy_text }}</span>
                </v-tooltip>
              </template>
            </v-text-field>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import { copyToClipboard } from '@/mixins/copyToClipboard.js'
import ProductVariantImagesDialog from "@/components/product/ProductVariantImagesDialog.vue"
export default {
  mixins: [copyToClipboard],
  components: {
    ProductVariantImagesDialog
  },
  props: {
    variant: Object,
    images: Array,
    hover: Boolean,
    dragging: Boolean,
    targetted_variant: String,
    access: Object,
    sku_rules: Array,
    barcode_rules: Array,
    single_variant: Boolean,
    single_convert: Boolean,
    convertToVariant: Function,
    convertToSingle: Function,
    uploadVariantImages: Function,
    variantIndex: Number
  },
  data() {
    return {
      valid: true,
      showVariantImages: false,
      droppedImages: [],
      counter: 0,
    }
  },
  computed: {
    variant_images() {
      let images = this.images.filter(image => image.variant_id === this.variant.variant_id);
      if (images.length > 0 && this.variant.variant_id !== undefined) {
        return images;
      } else {
        return [{'image_url': process.env.VUE_APP_NO_IMAGE_IMG}];
      }
    }
  },
  methods: {
    onDragEnter(e) {
      if (["application/x-moz-file", "Files"].includes(e.dataTransfer.types[0])) {
        this.$emit("update:targetted_variant", `variant_${this.variant.variant_id}`);
        this.$emit("update:dragging", true);
        this.counter++;
      }
    },
    onDragLeave() {
      if (this.counter > 0) {
        this.counter--;
      }
      if (this.counter === 0) {
        this.$emit("update:dragging", false);
      }
    },
    async onDrop(e) {
      if (this.dragging) {
        // Open the Variant Images Dialog
        this.showVariantImages = !this.showVariantImages;

        // Initialize an images buffer
        let images = [];

        // Iterate through the dropped files
        for (var file of e.dataTransfer.files) {
          // Initialize an image payload
          var image_payload = {
            file: file,
            urlResized: await this.convertToBase64(file),
            lastModified: file.lastModified,
            size: file.size,
            type: file.type,
            name: file.name
          }
          images.push(image_payload);
        }
        this.droppedImages = images;
        // Reset highlight state
        this.resetDraggingState();
      }
    },
    resetDraggingState() {
      this.$emit("update:hover", false);
      this.$emit("update:dragging", false);
      this.$emit("update:targetted_variant", null);
      this.counter = 0;
    },
    async convertToBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });
    },
    ...mapMutations({
      removeVariant: 'removeVariant'
    }),
  }
}
</script>

<style scoped>
.imageCard:hover {
  cursor: pointer;
}
.highlight {
  border: 2px solid #1976D2;
}
</style>