<template>
  <v-dialog v-model="show" max-width="600" @click:outside="$emit('update:show', false)" @keydown.esc="$emit('update:show', false)">
    <v-card elevation=4 outlined class="rounded-lg">
      <v-card-title>
        Edit Image
        <v-spacer />
        <v-btn class="mr-2" outlined color="error" @click="$emit('delete', image.image_id); $emit('update:show', false)">Delete</v-btn>
        <v-btn color="primary" outlined @click="$emit('download', image)">Download</v-btn>
      </v-card-title>
      <v-divider />
      <v-card-text class="pa-0 pt-4">
        <v-img :src="image.image_url" class="mx-auto image-border elevation-4" height=200 width=200 aspect-ratio=1 contain></v-img>
      </v-card-text>
      <v-divider class=my-4 />
      <v-card-text class="px-5">
        <v-text-field
          v-model="image.image_alt_text"
          label="Image Alt Text"
          outlined
          dense
          hide-details
          class="pb-4"
        ></v-text-field>
        <v-row dense>
          <v-col>
            <v-text-field
              :value="getFileSize(image.file_size)"
              label="File Size"
              outlined
              dense
              readonly
              hide-details
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              v-model="image.width"
              label="Width"
              outlined
              dense
              readonly
              hide-details
              suffix="px"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              v-model="image.height"
              label="Height"
              outlined
              dense
              readonly
              hide-details
              suffix="px"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              v-model="image.image_url"
              label="URL"
              outlined
              dense
              readonly
              hide-details
            >
              <template v-slot:append>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" @click="copyToClipboard(image.image_url)">mdi-clipboard-multiple-outline</v-icon>
                  </template>
                  <span>Copy to clipboard</span>
                </v-tooltip>
              </template>
            </v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    show: Boolean,
    image: Object,
  },
  methods: {
    getFileSize(bytes) {
      if (!bytes) return '0 Bytes';
      let size = bytes / 1000 / 1000;
      let type = 'MB';
      if (size < 1) {
        size = size * 1000;
        type = 'KB';
      }
      return `${Math.round(size * 100) / 100} ${type}`;
    },
    copyToClipboard(text) {
      navigator.clipboard.writeText(text);
    }
  }
}
</script>