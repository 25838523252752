<template>
  <v-window style="overflow: visible;">
    <v-tabs v-model="tab" center-active centered fixed-tabs show-arrows background-color="background" style="padding-bottom: 4px">
      <v-tab v-for="website in websites" :key="website.website_id" :style="`width: ${tabWidth}px`">
        {{ website.name }}
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab" style="overflow: visible;">
      <v-tab-item v-for="website in websites" :key="website.website_id" class="background">
        <ShopifySiteOptions
        v-if="website.type === 'Shopify' && ('options' in website) && ('website_options' in product) && (product.website_options[website.website_id])"
        :product="product"
        :brands="brands"
        :website="website"
        :access="access"
        :syncProduct="syncProduct"
        v-on="$listeners"
        ></ShopifySiteOptions>
        <B2BWaveSiteOptions
        v-else-if="website.type === 'B2BWave' && ('options' in website) && ('website_options' in product) && (product.website_options[website.website_id])"
        :product="product"
        :website="website"
        :access="access"
        :syncProduct="syncProduct"
        v-on="$listeners"
        ></B2BWaveSiteOptions>
        <v-container v-else>
          <v-skeleton-loader
            type="card-heading, list-item-three-line, image"
            elevation=2
            style="margin-bottom: 12px;"
          ></v-skeleton-loader>
        </v-container>
      </v-tab-item>
    </v-tabs-items>
  </v-window>
</template>

<script>
import ShopifySiteOptions from "@/components/product/website_options/shopify/ShopifySiteOptions.vue";
import B2BWaveSiteOptions from "@/components/product/website_options/b2bwave/B2BWaveSiteOptions.vue"
export default {
  data () {
    return {
      tab: null,
    }
  },
  props: {
    product: Object,
    brands: Array,
    websites: Array,
    access: Object,
    syncProduct: Function,
  },
  components: {
    ShopifySiteOptions,
    B2BWaveSiteOptions
  },
  computed: {
    tabWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return window.innerWidth / 5
        case 'sm': return window.innerWidth / 4
        case 'md': return window.innerWidth / 4
        case 'lg': return window.innerWidth / 3
        case 'xl': return window.innerWidth / 3
        default: return 400
      }
    }
  }
}
</script>